@import url("https://fonts.googleapis.com/css2?family=DotGothic16&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap");

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

#drum-machine {
  position: relative;
  margin-right: 100px;
  width: 400px;
  height: auto;
  padding: 10%;
  background-color: grey;
}

#drums {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  aspect-ratio: 1/1;
}

#display {
  padding: 10px;
  margin: 10px;
  background-color: black;
  font-family: "DotGothic16", sans-serif;
  color: #39ff14;
  border-radius: 5px;
}

.drum-pad {
  display: grid;
  margin: 2px;
  border: 2px solid #b7b7b7;
  border-radius: 50%;
  place-items: center;
  background-color: #4f4f4f;
  font-family: "Rubik Moonrocks", cursive;
  font-size: 3em;
  box-shadow: 20px 20px 1px;
  cursor: grab;
}

@media only screen and (max-width: 600px) {
  #drum-machine {
    margin-right: 70px;
    width: 300px;
    height: auto;
    padding: 10%;
    background-color: grey;
  }
}
